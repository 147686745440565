module.exports = {
  // Used to make CMS urls relative in post body
  cmsUrl: `chromaticblog`,
  // Used for Access-Control-Allow-Origin header in functions
  origin: `https://www.chromatic.com`,
  // Site domain. Do not include a trailing slash!
  siteUrl: `https://www.chromatic.com/blog`,
  domain: `https://blog.chromatic.com/`,
  // Logo in /static dir used for SEO, RSS, and App manifest
  siteIcon: `chromatic-favicon.png`,
  // Hiring banner
  hiring: true,
  // Used for App manifest e.g. Mobile Home Screen
  shortTitle: `News`,
  // This allows an alternative site title for meta data for pages.
  siteTitleMeta: `News and updates • Chromatic`,
  // This allows an alternative site description for meta data for pages.
  siteDescriptionMeta: `Updates and improvements to Chromatic`,
  // used on the home page
  tagLine: {
    header: `News and updates`,
  },
  // webmentions account
  webmentions: `chromatic.com_blog`,
  // Mailing list CTAs copy
  mailingList: {
    id: `17ebbc4cc4`,
    url: `https://hichroma.us15.list-manage.com/subscribe/post`,
    server: `us15`,
    fields: {
      u: `0cd563f2d5b0ef7aa4471c045`,
      id: `17ebbc4cc4`,
      MERGE3: 'ink',
    },

    footer: `Join the Ink newsletter to get free tutorials, guides, and resources emailed to you.`,
    banner: {
      title: `Get in-depth frontend guides emailed to you`,
      body: `Our team researches UI development best practices, summarizes our findings, and writes in-depth guides.`,
    },
    post: {
      title: `Did this article help you?`,
      body: `Get free UI development guides and tutorials like this emailed to you.`,
    },
    about: {
      title: `Join the newsletter`,
      body: `Get free UI development guides and tutorials like this emailed to you.`,
    },
  },
  // social media profiles
  socialMedia: {
    twitter: `https://twitter.com/chromaui`,
  },
  // used to attribute applause to users
  USER_ID_KEY: 'ink/user-id',
};
