import React from 'react';
import { styled } from '@storybook/theming';
import { NotFoundScreen } from '@storybook/components-marketing';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { Heading } from '../components/Heading';
import { Text } from '../components/Text';
import { GatsbyLink } from '../components/GatsbyLink';

const Container404 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45vh;
`;

const ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY;

// eslint-disable-next-line react/prop-types
const NotFoundPage = ({ data }) => (
  <Layout>
    {process.env.GATSBY_PUBLICATION === 'storybook' ? (
      <NotFoundScreen
        includeSpacing={false}
        repoUrl="https://github.com/storybookjs/frontpage/"
        // eslint-disable-next-line react/prop-types
        latestVersionString={data.dxData.latestVersion}
        apiKey={ALGOLIA_API_KEY}
      />
    ) : (
      <Container404>
        <article style={{ textAlign: `center` }}>
          <Heading level={3}>Error 404</Heading>
          <Text as="section">
            Page not found, <GatsbyLink to="/">return home</GatsbyLink> to start
            over
          </Text>
        </article>
      </Container404>
    )}
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
  query Page404Query {
    dxData {
      latestVersion
    }
  }
`;
