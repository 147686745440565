import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { Formik } from 'formik';
import { Button, Input, styles } from '@storybook/design-system';
import { useMailingListForm } from './useMailingListForm';

const MailingListFormUIWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const EmailInput = styled(Input)`
  flex: 1;
  && input {
    border-top-left-radius: ${styles.spacing.borderRadius.small}px;
    border-bottom-left-radius: ${styles.spacing.borderRadius.small}px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const SendButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${styles.spacing.borderRadius.small}px;
  border-bottom-right-radius: ${styles.spacing.borderRadius.small}px;
`;

let lastId = 0;

function uuid() {
  lastId += 1;
  return `email-${lastId}`;
}

const MailingListFormUI = ({
  handleBlur,
  handleChange,
  isSubmitting,
  value,
  ...rest
}) => (
  <MailingListFormUIWrapper {...rest}>
    <EmailInput
      id={uuid()}
      icon="email"
      type="email"
      name="email"
      value={value}
      placeholder="Your email"
      onChange={handleChange}
      onBlur={handleBlur}
      autoCapitalize="off"
      autoCorrect="off"
      appearance="secondary"
      label="Your email"
      hideLabel
    />

    <SendButton
      appearance="secondary"
      type="submit"
      isUnclickable={isSubmitting}
    >
      Send
    </SendButton>
  </MailingListFormUIWrapper>
);

MailingListFormUI.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

MailingListFormUI.defaultProps = {
  value: '',
};

const MailingListConfirm = styled.div`
  font-size: ${styles.typography.size.s2}px;
  background: ${styles.background.positive};
  line-height: 20px;
  padding: 10px;
  text-align: center;
  border-radius: ${styles.spacing.borderRadius.small}px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
`;

const FormWrapper = styled.form`
  max-width: 300px;
  position: relative;
`;

const validateForm = (values) => {
  if (!values.email) {
    return { email: 'Required' };
  }

  return {};
};

export const MailingListSignup = ({ sourceLocation, ...props }) => {
  const formRef = useRef(null);
  const [hasSubmitted, onSubmitForm] = useMailingListForm(sourceLocation);

  useEffect(() => {
    if (hasSubmitted && formRef.current) {
      const submitButtonEl = formRef.current.querySelector('[type="submit"]');
      if (submitButtonEl) {
        submitButtonEl.blur();
      }
    }
  }, [formRef, hasSubmitted]);

  return (
    <Formik
      initialValues={{ email: '' }}
      validate={validateForm}
      onSubmit={onSubmitForm}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <FormWrapper ref={formRef} onSubmit={handleSubmit}>
          <MailingListFormUI
            value={values.email}
            handleChange={handleChange}
            handleBlur={handleBlur}
            isSubmitting={isSubmitting}
            {...props}
          />
          {hasSubmitted && (
            <MailingListConfirm role="alert">
              <b>
                <span role="img" aria-label="thumbs up">
                  👍
                </span>{' '}
                Thanks, you&rsquo;re all signed up!
              </b>
            </MailingListConfirm>
          )}
        </FormWrapper>
      )}
    </Formik>
  );
};

MailingListSignup.propTypes = {
  sourceLocation: PropTypes.string,
};

MailingListSignup.defaultProps = {
  sourceLocation: '',
};
