import siteConfig from './siteConfig';
import { withPrefix } from 'gatsby';

export function cmsToRelativeLinks(html) {
  const cmsUrl = siteConfig.cmsUrl;

  const cmsUrlRegex = new RegExp(
    `https:\/\/${cmsUrl}\.ghost\.io\/(?!content)`, // eslint-disable-line
    'gm'
  );

  if (typeof html !== 'string') {
    return html;
  }

  return html.replace(cmsUrlRegex, withPrefix('/'));
}
