import { styled } from '@storybook/theming';
import PropTypes from 'prop-types';

export const AspectRatioPreserver = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${(props) => (props.height / props.width) * 100}%;

  & > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

AspectRatioPreserver.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
