import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { graphql } from 'gatsby';
import { styles, Icon } from '@storybook/design-system';
import {
  SubNav,
  SubNavBreadcrumb,
  SubNavRight,
  SubNavLinkList,
} from '@storybook/components-marketing';
import { Layout } from '../components/Layout';
import { Post } from '../components/Post/Post';
import { Mention } from '../components/WebMentions';
import { PopularPosts } from '../components/PopularPosts';
import { MetaData } from '../components/MetaData';
import { GatsbyLink } from '../components/GatsbyLink';
import { joinCommunityItems } from '../utils/joinCommunityItems';

const MarginWrapper = styled.div`
  ${styles.pageMargins}
`;

const PostPage = ({ data, mentions, pageContext, location }) => {
  const post = data.ghostPost;
  const settings = data.allGhostSettings.edges[0].node;

  return (
    <Layout
      includePageMargins={false}
      subNav={
        process.env.GATSBY_PUBLICATION === 'storybook' && (
          <SubNav>
            <SubNavBreadcrumb tertiary to="/" LinkWrapper={GatsbyLink}>
              <Icon icon="arrowleft" />
              Back to blog
            </SubNavBreadcrumb>
            <SubNavRight>
              <SubNavLinkList
                label="Join the community:"
                items={joinCommunityItems}
              />
            </SubNavRight>
          </SubNav>
        )
      }
    >
      <MetaData
        data={data}
        location={location}
        isPreview={pageContext.isPreview}
      />
      <MarginWrapper>
        <Post
          {...post}
          mentions={mentions}
          subscriberCount={data.mailingList.subscriberCount}
          ghostUrl={settings.url}
        />
      </MarginWrapper>
      <PopularPosts posts={pageContext.relatedPosts} />
    </Layout>
  );
};

PostPage.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.object.isRequired,
    mailingList: PropTypes.shape({
      subscriberCount: PropTypes.number,
    }).isRequired,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
  mentions: PropTypes.arrayOf(PropTypes.shape({ ...Mention.propTypes })),
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

PostPage.defaultProps = {
  mentions: [],
};

export const query = graphql`
  query PostPageQuery($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
    mailingList {
      subscriberCount
    }
    allGhostSettings {
      edges {
        node {
          url
        }
      }
    }
  }
`;

export default PostPage;
