import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../Text';

export const MailingListSubscriberCount = ({ count }) => (
  <Text variant="hint" data-chromatic="ignore">
    {numberWithCommas(count)} developers and counting
  </Text>
);

MailingListSubscriberCount.propTypes = {
  count: PropTypes.number.isRequired,
};

function numberWithCommas(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
