import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { ShadowBox } from '../ShadowBox';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { MailingListSubscriberCount } from './MailingListSubscriberCount';
import { MailingListSignup } from './MailingListSignup';
import { Stack } from '../Stack';

const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  form {
    margin-bottom: 12px;
  }

  @media (min-width: ${styles.breakpoint}px) {
    flex-direction: row;
    align-items: center;

    form {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

export const MailingListCTA = ({ title, body, subscriberCount }) => (
  <ShadowBox>
    <Stack space={4}>
      <Heading as="h3" level="4">
        {title}
      </Heading>
      <Text variant="caption">{body}</Text>
    </Stack>
    <SignupContainer>
      <MailingListSignup sourceLocation="post" />
      <MailingListSubscriberCount count={subscriberCount} />
    </SignupContainer>
  </ShadowBox>
);

MailingListCTA.propTypes = {
  subscriberCount: PropTypes.number.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
};

MailingListCTA.defaultProps = {
  title: 'Join the newsletter',
};
