import { css } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { darken } from 'polished';

require(`prismjs/components/prism-diff`);

const { color, typography, breakpoint } = styles;

const CODE_SNIPPET_CLASSNAME = '';

export const htmlFormatting = (props) => css`
  line-height: 28px;
  font-size: ${typography.size.s3}px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1em;
    & + * {
      margin-top: 0 !important;
    }
    .remark-header-link svg {
      opacity: 0;
      transition: opacity 250ms ease-out, visibility 0ms linear 250ms;
    }
    &:hover .remark-header-link svg {
      opacity: 1;
      transition: opacity 250ms ease-out;
    }
  }
  hr {
    margin: 1.5rem 0 3em 0;
  }
  hr + * {
    margin-top: 0 !important;
  }
  h1 {
    font-size: ${typography.size.l1}px;
    font-weight: ${typography.weight.bold};
    line-height: 36px;
    margin-bottom: 1.5rem;
  }
  h2 {
    font-size: ${typography.size.m2}px;
    font-weight: ${typography.weight.bold};
    line-height: ${typography.size.m3}px;
    margin-bottom: 0.5em;
  }
  h2:not(:first-child) {
    margin-top: 1.5rem;
  }
  h3 {
    font-size: ${typography.size.m1}px;
    font-weight: ${typography.weight.bold};
    line-height: 28px;
    margin: 1.5rem 0 0.5rem;
  }
  h3:first-child {
    margin-top: 0;
  }
  h4 {
    font-size: ${typography.size.s3}px;
    font-weight: ${typography.weight.bold};
    margin: 1rem 0 0.5rem;
  }
  p {
    margin: 0 0 1.5em 0;
    position: relative;
    &:first-of-type:not(:only-of-type) {
      margin-top: 0;
    }
    &:only-of-type {
      margin: 0;
    }
  }
  em {
    font-weight: inherit;
  }
  strong {
    font-weight: ${typography.weight.extrabold};
  }
  ol,
  ul {
    list-style-position: outside;
    margin-bottom: 1.5em;
    margin-top: 0;
    /* margin-top: 1.5em; */
    padding-left: 30px;
    li {
      margin-bottom: 0.25em;
    }
    ul,
    ol {
      margin: 0 0 0.5em 0;
    }
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  a:not(.remark-header-link) {
    transition: all 250ms ease-out;
    text-decoration: none;
    transform: translate3d(0, 0, 0);
    &,
    &:hover,
    &:focus,
    &:hover:focus {
      color: ${darken(0.2, color.secondary)};
    }
    &:hover {
      transform: translate3d(0, -1px, 0);
    }
    &:active {
      transform: translate3d(0, 0, 0);
    }
  }
  .remark-header-link {
    display: inline-flex;
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    transition: transform 250ms ease-out;
    transform: translate3d(-100%, -50%, 0);
    padding-right: 10px;
    &:hover {
      transform: translate3d(-100%, calc(-50% - 1px), 0);
      path {
        fill: ${color.dark};
      }
    }
    path {
      fill: ${color.mediumdark};
      transition: fill 250ms ease-out;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }
  figure {
    clear: both;
    margin: 1.5em 0 3em 0;
    figcaption {
      font-size: ${typography.size.s2}px;
      text-align: center;
      margin: 1em auto 0;
    }
  }
  img {
    display: block;
    padding: 0;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    &.alignright {
      float: right;
      margin-right: 0;
    }
    &.alignleft {
      float: left;
      margin-left: 0;
    }
    &.aligncenter {
      display: block;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
      /* margin-top: 1em; */
    }
  }
  .aside {
    font-size: 87.5%;
    line-height: 1.43;
    color: ${color.dark};
    background: #f8fafc;
    border-radius: ${styles.spacing.borderRadius.small}px;
    padding: 1em;
    margin: 0 0 1.5em 0;
    p:last-child {
      margin-bottom: 0;
    }
  }
  /* Tables based on GH markdown format */
  table {
    font-size: ${typography.size.s2}px;
    padding: 0;
    border-collapse: collapse;
    width: 100%;
    margin: 2em 0;
    overflow: auto;
  }
  table tr {
    border-top: 1px solid ${color.mediumlight};
    background-color: white;
    margin: 0;
    padding: 0;
  }
  table tr:nth-child(2n) {
    background-color: ${color.lighter};
  }
  table tr th {
    font-weight: bold;
    border: 1px solid ${color.medium};
    border-radius: 3px 3px 0 0;
    text-align: left;
    margin: 0;
    padding: 0.5em 0.75em;
  }
  table tr td {
    border: 1px solid #ddd;
    text-align: left;
    margin: 0;
    padding: 0.5em 1em;
  }
  table tr th :first-child,
  table tr td:first-child {
    margin-top: 0;
  }
  table tr th :last-child,
  table tr td:last-child {
    margin-bottom: 0;
  }
  iframe {
    border: none;
    width: 100% !important;
    max-width: none !important;
  }
  video {
    max-width: 100%;
    display: block;
    margin: 2em 0;
  }
  /* Pre and Code styles */
  .${CODE_SNIPPET_CLASSNAME} {
    margin: 0 0 1.5em 0;
  }
  code {
    font-size: 87.5%;
    color: inherit;
    -webkit-text-size-adjust: 100%;
  }

  & :not(pre) > code {
    display: inline-block;
    vertical-align: baseline;
    line-height: 1;
    padding: 3px 5px;

    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  pre {
    /* Reset styles from global */
    margin: 0 0 1.5em 0;
    /* End resets */
    padding: 1em;
    font-size: inherit;
    overflow: auto;
    max-height: 600px;
    max-width: 100%;
    code {
      padding: 0;
      line-height: 1.43; /* 14px/20px */
    }
  }
  blockquote {
    font-size: ${typography.size.m1}px;
    color: ${color.dark};
    line-height: 1.75;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
  }
  details {
    margin: 0 0 1.5em 0;
    h1,
    h2,
    h3,
    h4 {
      display: inline;
    }
    &:not([open]) + details {
      margin-top: -1em;
    }
  }
  details > summary {
    display: inline-block;
    cursor: pointer;
    color: ${darken(0.2, color.secondary)};
  }
  details[open] {
    position: relative;

    > summary {
      margin-bottom: 1em;
    }
    > summary ~ * {
      margin-left: 30px;
    }
  }
  details > summary::-webkit-details-marker {
    height: 10px;
    width: 10px;
  }

  /**
   * Images
   */
  .kg-width-wide,
  .kg-width-full {
    background: ${color.lightest};
    z-index: 1;
  }

  .kg-width-full img {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: ${breakpoint * 1.75}px) {
    .kg-width-wide img {
      width: ${props.theme.widths.regular}px;
      max-width: ${props.theme.widths.regular}px;
    }

    .kg-width-full img {
      width: 100vw;
      max-width: 100vw;
    }
  }

  /**
   * Gallery Styles
   */
  .kg-gallery-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: ${props.theme.widths.regular}px;
    background: ${color.lightest};
    z-index: 1;
  }

  .kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .kg-gallery-image {
      flex: 1 1 0;
    }
  }

  .kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
  }

  .kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
  }

  .kg-gallery-card + .kg-image-card.kg-width-wide,
  .kg-gallery-card + .kg-gallery-card,
  .kg-image-card.kg-width-wide + .kg-gallery-card,
  .kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
    margin: -2.25em 0 3em;
  }

  .kg-image {
    width: auto;
    height: auto;
  }

  /**
   * Bookmark
  */
  && {
    .kg-bookmark-card {
      width: 100%;
    }

    .kg-bookmark-container {
      display: flex;
      text-decoration: none;
      border-radius: 3px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 5px 15px 0 rgba(0, 0, 0, 0.05);

      color: ${color.darkest};

      &:hover,
      &:focus,
      &:hover:focus {
        text-decoration: none;
        color: inherit;
      }
    }

    .kg-bookmark-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px;
    }

    .kg-bookmark-title {
      font-size: ${typography.size.s3}px;
      line-height: 1.5em;
      font-weight: ${typography.weight.extrabold};
    }

    .kg-bookmark-description {
      display: -webkit-box;
      overflow-y: hidden;
      margin-top: 4px;
      max-height: 48px;
      color: ${color.dark};
      font-size: ${typography.size.s3}px;
      line-height: 1.5em;

      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .kg-bookmark-thumbnail {
      position: relative;
      min-width: 33%;
      max-height: 100%;
    }

    .kg-bookmark-thumbnail img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 3px 3px 0;

      object-fit: cover;
    }

    .kg-bookmark-metadata {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 12px;
      color: ${color.mediumdark};
      font-size: ${typography.size.s1}px;
      font-weight: 400;
    }

    .kg-bookmark-icon {
      margin-right: 8px;
      width: 22px;
      height: 22px;
    }

    .kg-bookmark-author {
      line-height: 1.5em;
    }

    .kg-bookmark-author:after {
      content: '•';
      margin: 0 6px;
    }

    .kg-bookmark-publisher {
      overflow: hidden;
      max-width: 240px;
      line-height: 1.5em;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: ${breakpoint}px) {
      .kg-bookmark-container {
        flex-direction: column;
      }

      .kg-bookmark-title,
      .kg-bookmark-description {
        font-size: ${typography.size.s2}px;
        line-height: 1.5em;
      }

      .kg-bookmark-icon {
        width: 18px;
        height: 18px;
      }

      .kg-bookmark-thumbnail {
        order: 1;
        min-height: 160px;
        width: 100%;
      }

      .kg-bookmark-thumbnail img {
        border-radius: 3px 3px 0 0;
      }

      .kg-bookmark-content {
        order: 2;
      }
    }
  }

  /**
   * Callout Card
   */
  .kg-callout-card {
    background-color: #f6f9fc;
    border-radius: 4px;
    color: #444;
    display: flex;
    margin-bottom: 1.5em;
    padding: 1.2em 1.6em;
  }

  .kg-callout-card a {
    color: #1ea7fd !important;
    font-weight: 400;
  }

  .kg-callout-card div.kg-callout-emoji {
    padding-right: 0.8em;
    line-height: 1.25em;
    font-size: 1.15em;
  }

  .kg-callout-card div.kg-callout-text {
    font-size: 0.95em;
    line-height: 1.5em;
  }

  .kg-callout-card + .kg-callout-card {
    margin-top: 1em;
  }

  /**
   * Embed Card
  */
  .kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .kg-embed-card .fluid-width-video-wrapper {
    margin: 0;
  }

  .fluid-width-video-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .kg-embed-gist {
    margin: 1em 0;
  }

  .fluid-width-video-wrapper iframe,
  .fluid-width-video-wrapper object,
  .fluid-width-video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /**
   * Video Card
  */
  ${video(props)}
`;

const video = (props) => `
.kg-video-card,
.kg-video-card * {
    box-sizing: border-box;
}

.kg-video-card {
    position: relative;
    --seek-before-width: 0%;
    --volume-before-width: 100%;
    --buffered-width: 0%;
}

.kg-video-card video {
    display: block;
    max-width: 100%;
    height: auto;
}

.kg-video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kg-video-container video {
  margin: 0;
}

.kg-video-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg,rgba(0,0,0,0.3) 0,transparent 70%,transparent 100%);
    z-index: 999;
    transition: opacity .2s ease-in-out;
}

.kg-video-large-play-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    padding: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: opacity .2s ease-in-out;
}

.kg-video-large-play-icon svg {
    width: 20px;
    height: auto;
    margin-left: 2px;
    fill: #fff;
}

.kg-video-player-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.5));
    z-index: 999;
    transition: opacity .2s ease-in-out;

}

.kg-video-player {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 9999;
    padding: 12px 16px;
}

.kg-video-current-time {
    min-width: 38px;
    padding: 0 4px;
    color: #fff;
    font-family: inherit;
    font-size: .85em;
    font-weight: 500;
    line-height: 1.4em;
    white-space: nowrap;
}

.kg-video-time {
    color: rgba(255, 255, 255, 0.6);
    font-family: inherit;
    font-size: .85em;
    font-weight: 500;
    line-height: 1.4em;
    white-space: nowrap;
}

.kg-video-duration {
    padding: 0 4px;
}

.kg-video-play-icon,
.kg-video-pause-icon {
    position: relative;
    padding: 0px 4px 0 0;
    font-size: 0;
    background: transparent;
}

.kg-video-hide {
    display: none !important;
}

.kg-video-hide-animated {
    opacity: 0 !important;
    transition: opacity .2s ease-in-out;
    cursor: initial;
}

.kg-video-play-icon svg,
.kg-video-pause-icon svg {
    width: 14px;
    height: 14px;
    fill: #fff;
}

.kg-video-seek-slider {
    flex-grow: 1;
    margin: 0 4px;
}

@media (max-width: 520px) {
    .kg-video-seek-slider {
        display: none;
    }
}

.kg-video-playback-rate {
    min-width: 37px;
    padding: 0 4px;
    color: #fff;
    font-family: inherit;
    font-size: .85em;
    font-weight: 600;
    line-height: 1.4em;
    text-align: left;
    background: transparent;
    white-space: nowrap;
}

@media (max-width: 520px) {
    .kg-video-playback-rate {
        padding-left: 8px;
    }
}

.kg-video-mute-icon,
.kg-video-unmute-icon {
    position: relative;
    bottom: -1px;
    padding: 0 4px;
    font-size: 0;
    background: transparent;
}

@media (max-width: 520px) {
    .kg-video-mute-icon,
    .kg-video-unmute-icon  {
        margin-left: auto;
    }
}

.kg-video-mute-icon svg,
.kg-video-unmute-icon svg {
    width: 16px;
    height: 16px;
    fill: #fff;
}

.kg-video-volume-slider {
    width: 80px;
}

@media (max-width: 300px) {
    .kg-video-volume-slider {
        display: none;
    }
}

.kg-video-seek-slider::before {
    content: "";
    position: absolute;
    left: 0;
    width: var(--seek-before-width) !important;
    height: 4px;
    cursor: pointer;
    background-color: #EBEEF0;
    border-radius: 2px;
}

.kg-video-volume-slider::before {
    content: "";
    position: absolute;
    left: 0;
    width: var(--volume-before-width) !important;
    height: 4px;
    cursor: pointer;
    background-color: #EBEEF0;
    border-radius: 2px;
}

/* Resetting browser styles
/* --------------------------------------------------------------- */

.kg-video-card input[type=range] {
    position: relative;
    -webkit-appearance: none;
    background: transparent;
}

.kg-video-card input[type=range]:focus {
    outline: none;
}

.kg-video-card input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.kg-video-card input[type=range]::-ms-track {
    cursor: pointer;
    border-color: transparent;
    color: transparent;
    background: transparent;
}

.kg-video-card button {
    display: flex;
    align-items: center;
    border: 0;
    cursor: pointer;
}

.kg-video-card input[type="range"] {
    height: auto;
    padding: 0;
    border: 0;
}

/* Chrome & Safari styles
/* --------------------------------------------------------------- */

.kg-video-card input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
}

.kg-video-card input[type="range"]::-webkit-slider-thumb {
    position: relative;
    box-sizing: content-box;
    width: 13px;
    height: 13px;
    margin: -5px 0 0 0;
    border: 0;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 1px 4px rgba(0,0,0,0.24);
}

.kg-video-card input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
}

/* Firefox styles
/* --------------------------------------------------------------- */

.kg-video-card input[type="range"]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
}

.kg-video-card input[type="range"]::-moz-range-progress {
    background: #EBEEF0;
    border-radius: 2px;
}

.kg-video-card input[type="range"]::-moz-range-thumb {
    box-sizing: content-box;
    width: 13px;
    height: 13px;
    border: 0;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 1px 4px rgba(0,0,0,0.24);
}

.kg-video-card input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
}

/* Edge & IE styles
/* --------------------------------------------------------------- */

.kg-video-card input[type="range"]::-ms-track {
    width: 100%;
    height: 3px;
    border: solid transparent;
    color: transparent;
    cursor: pointer;
    background: transparent;
}

.kg-video-card input[type="range"]::-ms-fill-lower {
    background: #fff;
}

.kg-video-card input[type="range"]::-ms-fill-upper {
    background: #EBEEF0;
}

.kg-video-card input[type="range"]::-ms-thumb {
    box-sizing: content-box;
    width: 13px;
    height: 13px;
    border: 0;
    cursor: pointer;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 1px 4px rgba(0,0,0,0.24);
}

.kg-video-card input[type="range"]:active::-ms-thumb {
    transform: scale(1.2);
}

/* Video sizes styles
/* --------------------------------------------------------------- */
@media (min-width: ${breakpoint * 1.75}px) {
  .kg-width-wide video {
    width: ${props.theme.widths.regular}px;
    max-width: ${props.theme.widths.regular}px;
  }

  .kg-width-full video {
    width: 100vw;
    max-width: 100vw;
  }
}
`;
