import { useEffect, useState } from 'react';

export function useWideContentVisible(ref, query, options) {
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    if (ref.current && typeof IntersectionObserver === 'function') {
      const handler = (entries) => {
        let nextState = false;

        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            nextState = true;
          }
        });

        setContentVisible(nextState);
      };

      const observer = new IntersectionObserver(handler, options);

      ref.current.querySelectorAll(query).forEach((element) => {
        // eslint-disable-next-line
        observer.observe(element);
      });

      return () => {
        setContentVisible(false);
        // eslint-disable-next-line
        observer.disconnect();
      };
    }
    return () => {};
  }, [ref.current, options.threshold, options.root, options.rootMargin]);

  return contentVisible;
}
