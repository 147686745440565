import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { Link } from '@storybook/design-system';
import { Link as RawGatsbyLink } from 'gatsby';

export const GatsbyLink = (props) => (
  <Link LinkWrapper={RawGatsbyLink} {...props} />
);

export const GatsbyBlockLink = styled(RawGatsbyLink)`
  display: block;
  transition: transform 150ms ease-out;
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    transform: translate3d(0, -3px, 0);
    color: inherit;
  }

  &:active {
    transform: translate3d(0, 0, 0);
    color: inherit;
  }
`;

export const GatsbyLinkWrapper = React.forwardRef(
  (
    // eslint-disable-next-line react/prop-types, no-unused-vars
    { href, appearance, containsIcon, disabled, inverse, isLoading, ...props },
    ref
  ) => <RawGatsbyLink to={href} ref={ref} {...props} />
);
GatsbyLinkWrapper.propTypes = {
  href: PropTypes.string.isRequired,
};
GatsbyLinkWrapper.displayName = 'GatsbyLinkWrapper';
