import React from 'react';
import PropTypes from 'prop-types';
import { styled, css } from '@storybook/theming';
import { TooltipMessage, styles } from '@storybook/design-system';
const { background, color, typography, spacing } = styles;

const List = styled.ul`
  min-width: 180px;
  border-radius: ${spacing.borderRadius.small}px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  transition: all 150ms ease-out;
  display: block;
  padding: 7px 15px;

  font-size: ${typography.size.s1}px;
  color: ${color.darker};
  font-weight: ${typography.weight.bold};
  line-height: 18px;
  cursor: pointer;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.highlighted &&
    css`
      background: ${background.calmBlue};
    `}}

  list-style: none;

  &:not(:first-of-type) {
    border-top: 1px solid ${color.border};
  }
`;

export const SearchResults = ({
  results,
  getItemProps,
  getMenuProps,
  highlightedIndex,
}) => (
  <List {...getMenuProps({}, { suppressRefError: true })}>
    {results.length > 0 ? (
      results.map((item, index) => (
        <Item
          key={`${item.title}${index}`}
          {...getItemProps({ index, item })}
          highlighted={index === highlightedIndex}
        >
          {item.title}
        </Item>
      ))
    ) : (
      <TooltipMessage desc="no results found" />
    )}
  </List>
);

SearchResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  getItemProps: PropTypes.func,
  getMenuProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
};
