import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { PostCard } from './PostCard';

const { color, typography, pageMargins } = styles;

const SectionTitle = styled.h2`
  color: ${color.darkest};
  font-weight: ${typography.weight.bold};
  font-size: ${typography.size.l1}px;
  line-height: 36px;
  letter-spacing: -0.44px;
  margin-bottom: 32px;
`;

const PostList = styled.section`
  margin-bottom: ${(props) => props.theme.space.large}px;
  display: grid;
  grid-gap: ${(props) => props.theme.grid.gap}px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  @media (min-width: ${styles.breakpoint * 1.5}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const PopularPostsInner = styled.section`
  margin-top: 64px;
  padding-top: 64px;
  padding-top: 80px;
  border-top: 1px solid ${color.border};
`;

const MarginWrapper = styled.div`
  ${pageMargins}
`;

export const PopularPosts = ({ posts }) => (
  <PopularPostsInner>
    <MarginWrapper>
      <SectionTitle>Popular posts</SectionTitle>
      <PostList>
        {posts.map(({ node }) => (
          <PostCard key={node.id} variant="small" {...node} />
        ))}
      </PostList>
    </MarginWrapper>
  </PopularPostsInner>
);

PopularPosts.propTypes = {
  posts: PropTypes.array.isRequired,
};
