import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { Button, Icon, styles } from '@storybook/design-system';
import { Link } from 'gatsby';

const PaginationNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const PaginationCopy = styled.div`
  margin-left: ${(props) => (props.hasPrevious ? 20 : 60)}px;
  margin-right: ${(props) => (props.hasNext ? 20 : 60)}px;
  color: ${styles.color.darkest};
  font-weight: ${styles.typography.weight.bold};
  font-size: ${styles.typography.size.s3}px;
  line-height: 22px;
`;

PaginationCopy.propTypes = {
  hasPrevious: PropTypes.bool.isRequired,
  hasNext: PropTypes.bool.isRequired,
};

export const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
    pageContext;

  return numberOfPages > 1 ? (
    <PaginationNav role="navigation" aria-label="Pagination Navigation">
      <div>
        {previousPagePath && (
          <Button
            ButtonWrapper={Link}
            appearance="outline"
            containsIcon
            to={previousPagePath}
            rel="prev"
          >
            <Icon icon="arrowleft" aria-label="Goto previous page" />
          </Button>
        )}
      </div>
      {numberOfPages > 1 && (
        <PaginationCopy
          hasPrevious={!!previousPagePath}
          hasNext={!!nextPagePath}
        >
          {humanPageNumber} of {numberOfPages}
        </PaginationCopy>
      )}
      <div>
        {nextPagePath && (
          <Button
            ButtonWrapper={Link}
            appearance="outline"
            containsIcon
            to={nextPagePath}
            rel="next"
          >
            <Icon icon="arrowright" aria-label="Goto next page" />
          </Button>
        )}
      </div>
    </PaginationNav>
  ) : null;
};

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
