import React, { useContext } from 'react';
import { ApplauseSummary } from './ApplauseSummary';
import { ApplauseContext } from './ApplauseContext';

export const Applause = () => {
  const [applauseCount, onApplause, isLoading] = useContext(ApplauseContext);

  return (
    <ApplauseSummary
      handleClick={onApplause}
      count={applauseCount}
      isLoading={isLoading}
    />
  );
};
