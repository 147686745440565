import React from 'react';
import PropTypes from 'prop-types';
import { css, Global, styled } from '@storybook/theming';
import { Input, WithTooltip, styles } from '@storybook/design-system';
import { navigate } from 'gatsby';
import { useCombobox } from 'downshift';
import { useSearch } from './useSearch';
import { SearchResults } from './SearchResults';

const SearchInput = styled(Input)`
  display: none;
  margin-right: 20px;

  & input {
    font-size: 14px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  @media (min-width: ${styles.breakpoint * 1.25}px) {
    display: block;
    max-width: ${(props) => props.theme.grid.column * 0.5}px;
  }
`;

const ToolTipStyles = ({ hide }) => (
  <Global
    styles={css`
      @media (min-width: ${1.25 * styles.breakpoint}px) {
        body {
          [role='tooltip'][data-placement='bottom-start'] {
            visibility: ${hide ? 'hidden' : 'visible'};
          }
        }
      }
    `}
  />
);

ToolTipStyles.propTypes = {
  hide: PropTypes.bool.isRequired,
};

// Handle state changes for the typeahead as search query changes
function stateReducer(state, actionAndChanges) {
  const { type, changes } = actionAndChanges;

  switch (type) {
    case useCombobox.stateChangeTypes.InputBlur:
      return {
        ...changes,
        selectedItem: null,
      };
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      if (changes.selectedItem) {
        navigate(changes.selectedItem.url);
      }
      return {
        ...changes,
        selectedItem: null,
        inputValue: '',
      };
    default:
      return changes;
  }
}

export const Search = ({ posts }) => {
  const [searchQuery, searchResults, handleSearch] = useSearch(posts);

  const links = searchResults.slice(0, 12).map((node) => {
    return {
      title: node.title,
      url: node.url,
    };
  });

  const {
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    selectedItem: searchQuery,
    defaultHighlightedIndex: 0,
    itemToString: (item) => (item ? item.title : ''),
    items: links,
    onInputValueChange: ({ inputValue }) => {
      handleSearch(inputValue);
    },
    stateReducer,
  });

  return (
    <>
      <ToolTipStyles hide={searchQuery === '' && links.length === 0} />
      <div {...getComboboxProps()}>
        <WithTooltip
          placement="bottom-start"
          trigger="focus"
          tooltip={
            <SearchResults
              results={links}
              getMenuProps={getMenuProps}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
            />
          }
        >
          <SearchInput
            id="js-post-search"
            label="Search"
            hideLabel
            icon="search"
            appearance="pill"
            placeholder="Find posts"
            {...getInputProps()}
            value={searchQuery}
          />
        </WithTooltip>
      </div>
    </>
  );
};

Search.propTypes = {
  posts: PropTypes.array.isRequired,
};
