import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { Applause } from '../Applause';
import { ShareLinks } from '../ShareLinks';

const SidebarInner = styled.aside`
  max-width: 100px;
  position: sticky;
  top: 3rem;
`;

const ApplauseWrapper = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${styles.color.border};
`;

export const PostSidebar = ({ shareText, link, authorTwitter }) => (
  <SidebarInner>
    <ApplauseWrapper>
      <Applause />
    </ApplauseWrapper>
    <ShareLinks
      shareText={shareText}
      link={link}
      authorTwitter={authorTwitter}
      axis="vertical"
    />
  </SidebarInner>
);

PostSidebar.propTypes = {
  shareText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  authorTwitter: PropTypes.string.isRequired,
};
