import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles, Link, Icon } from '@storybook/design-system';
import { Applause } from '../Applause';
import { InteractionsList } from './InteractionsList';
import { Stack } from '../Stack';
import { Mention } from './Mention';
import { ShareLinks } from '../ShareLinks';

const WebMentionsInner = styled(Stack)`
  @media (min-width: ${styles.breakpoint}px) {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const TweetLink = styled(Link)`
  span {
    display: inline-block;
    line-height: 36px;
    font-weight: ${styles.typography.weight.bold};
    font-size: ${styles.typography.size.s1}px;

    @media (min-width: ${styles.breakpoint}px) {
      font-size: ${styles.typography.size.s3}px;
      padding-right: 6px;
    }
  }

  && svg {
    width: 36px;
    height: 36px;
    color: #dfdfdf;
    background-color: #f3f3f3;
    border-radius: 100%;
    padding-top: 2px;
    vertical-align: middle;
    position: initial;
    bottom: initial;
    margin-right: 16px;
  }
`;

export const WebMentions = ({
  webMentions,
  shareText,
  link,
  authorTwitter,
}) => {
  const tweetContent = `${shareText} by ${authorTwitter} ${link}`;

  return (
    <WebMentionsInner id="web-mentions" as="aside" space="large">
      <Stack
        axis="horizontal"
        space={28}
        alignment="start"
        distribution="space-between"
      >
        <Applause />
        {webMentions.interactions.length > 0 ? (
          <InteractionsList
            mentions={webMentions.interactions}
            size="large"
            limit={12}
          />
        ) : (
          <ShareLinks
            shareText={shareText}
            link={link}
            authorTwitter={authorTwitter}
            axis="horizontal"
          />
        )}
      </Stack>
      {webMentions.responses.length > 0 && (
        <Stack space={20} alignment="start">
          <Stack space={20}>
            {webMentions.responses.map((mention) => (
              <Mention key={mention.id} {...mention} />
            ))}
          </Stack>
          <TweetLink
            hasIcon
            href={`https://twitter.com/intent/tweet?text=${tweetContent}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon="useralt" aria-hidden /> Tweet about this article to show
            up here
          </TweetLink>
        </Stack>
      )}
    </WebMentionsInner>
  );
};

WebMentions.propTypes = {
  webMentions: PropTypes.shape({
    interactions: PropTypes.arrayOf(PropTypes.shape({ ...Mention.propTypes }))
      .isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape({ ...Mention.propTypes }))
      .isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
  shareText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  authorTwitter: PropTypes.string.isRequired,
};
