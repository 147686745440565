export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export function debounce(callback, wait, timeoutId = null) {
  return (...args) => {
    window.clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

export function getFileExtension(file = '') {
  const extension = file.split('.').pop();
  return extension;
}
