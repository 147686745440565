import { css, styled } from '@storybook/theming';
import PropTypes from 'prop-types';
import { styles } from '@storybook/design-system';

const { color, typography } = styles;

export const Text = styled.p`
  color: ${color.darkest};
  font-size: ${typography.size.s3}px;
  line-height: ${typography.size.m2}px;
  margin-top: 0;
  margin-bottom: 0;

  ${(props) =>
    props.variant === 'lead' &&
    css`
      font-size: ${typography.size.m1}px;
      line-height: ${typography.size.l1}px;
    `}

  ${(props) =>
    props.variant === 'caption' &&
    css`
      font-size: ${typography.size.s2}px;
      line-height: ${typography.size.m2}px;
    `}

  ${(props) =>
    props.variant === 'hint' &&
    css`
      font-size: ${typography.size.s2}px;
      line-height: 18px;
      color: ${color.mediumdark};
    `}

  ${(props) =>
    props.emphasis &&
    css`
      color: inherit;
      font-weight: ${typography.weight.extrabold};
    `}
`;

Text.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['lead', 'caption', 'hint']),
  emphasis: PropTypes.bool,
};
