import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Icon } from '@storybook/design-system';
import {
  SubNav,
  SubNavBreadcrumb,
  SubNavRight,
  SubNavLinkList,
} from '@storybook/components-marketing';
import { Layout } from '../components/Layout';
import { Container } from '../components/Container';
import { PageHeader } from '../components/PageHeader';
import { Pagination } from '../components/Pagination';
import { PostGrid } from '../components/PostGrid';
import { MetaData } from '../components/MetaData';
import { GatsbyLink } from '../components/GatsbyLink';
import { joinCommunityItems } from '../utils/joinCommunityItems';

const TagPage = ({ data, pageContext, location }) => {
  const posts = data.allGhostPost.edges;
  const tag = data.ghostTag;

  return (
    <Layout
      subNav={
        process.env.GATSBY_PUBLICATION === 'storybook' && (
          <SubNav>
            <SubNavBreadcrumb tertiary to="/" LinkWrapper={GatsbyLink}>
              <Icon icon="arrowleft" />
              Back to blog
            </SubNavBreadcrumb>
            <SubNavRight>
              <SubNavLinkList
                label="Join the community:"
                items={joinCommunityItems}
              />
            </SubNavRight>
          </SubNav>
        )
      }
    >
      <MetaData data={data} location={location} />
      <Container size="wide">
        <PageHeader title={`${tag.name} articles`} subtitle={tag.description} />
        <PostGrid
          posts={posts}
          subscriberCount={data.mailingList.subscriberCount}
        />
        <Pagination
          pageContext={pageContext}
          subscriberCount={data.mailingList.subscriberCount}
        />
      </Container>
    </Layout>
  );
};

TagPage.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
    mailingList: PropTypes.shape({
      subscriberCount: PropTypes.number,
    }).isRequired,
    ghostTag: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export const query = graphql`
  query TagPageQuery($slug: String!, $limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: {
        tags: { elemMatch: { slug: { eq: $slug, nin: ["hash-preview"] } } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
    mailingList {
      subscriberCount
    }
    ghostTag(slug: { eq: $slug }) {
      slug
      name
      description
    }
  }
`;

export default TagPage;
