import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { Heading } from './Heading';
import { Text } from './Text';

const PageHeaderWrapper = styled.div`
  border-bottom: 1px solid ${styles.color.border};
`;

const PageTitle = styled(Heading)`
  max-width: ${(props) => props.theme.grid.column * 2}px;
`;

const PageSubtitle = styled((props) => <Text {...props} variant="lead" />)`
  margin-top: ${(props) => props.theme.space.small}px;
  max-width: ${(props) => props.theme.grid.column * 2}px;
`;

const PageHeaderControls = styled.div`
  margin-top: ${(props) => props.theme.space.medium}px;
  margin-bottom: 32px;
`;

const TitleWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.space.medium}px;
`;

export const PageHeader = ({ title, subtitle, controls }) => (
  <PageHeaderWrapper>
    <TitleWrapper>
      <PageTitle as="h1" level="1">
        {title}
      </PageTitle>
      {subtitle && <PageSubtitle>{subtitle}</PageSubtitle>}
    </TitleWrapper>
    {controls && <PageHeaderControls>{controls}</PageHeaderControls>}
  </PageHeaderWrapper>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  controls: PropTypes.node,
};
