import React, { createContext, useMemo } from 'react';
import { usePersistedState } from './usePersistedState';
import { v4 as uuidv4 } from 'uuid';
import siteConfig from './siteConfig';

export const UserIdContext = createContext();

export const UserIdProvider = ({ children }) => {
  const [userId] = usePersistedState(uuidv4(), siteConfig.USER_ID_KEY);

  const value = useMemo(() => [userId], [userId]);

  return (
    <UserIdContext.Provider value={value}>{children}</UserIdContext.Provider>
  );
};
