import React from 'react';
import PropTypes from 'prop-types';
import { css, styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { Author } from '../Author';
import { Heading } from '../Heading';
import { GatsbyBlockLink } from '../GatsbyLink';
import { PostExcerpt } from './PostExcerpt';
import { PostHeader } from './PostHeader';
import { AspectRatioPreserver } from '../AspectRatioPreserver';
import { PostImage } from './PostImage';

const { breakpoint } = styles;

const variantPropType = PropTypes.oneOf(['large', 'small']).isRequired;

const PostImageLink = styled(GatsbyBlockLink)`
  width: 100%;

  ${(props) =>
    props.variant === 'large' &&
    css`
      @media (min-width: ${breakpoint * 1.5}px) {
        flex: 2 1 ${props.theme.grid.column * 2 + props.theme.grid.gap}px;
        width: auto;
      }
    `};
`;

PostImageLink.propTypes = {
  variant: variantPropType,
};

const Article = styled.article`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${(props) =>
    props.variant === 'large' &&
    css`
      @media (min-width: ${breakpoint * 1.5}px) {
        flex-direction: row;
      }
    `}
`;

Article.propTypes = {
  variant: variantPropType,
};

const PostContent = styled.div`
  margin-top: ${(props) => props.theme.space.medium}px;

  ${(props) =>
    props.variant === 'small' &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.variant === 'large' &&
    css`
      @media (min-width: ${breakpoint * 1.5}px) {
        margin-top: 18px;
        margin-left: ${props.theme.grid.gap}px;
        flex: 1 1 ${props.theme.grid.column}px;
      }
    `}
`;

PostContent.propTypes = {
  variant: variantPropType,
};

export const PostCard = ({
  title,
  excerpt,
  slug,
  feature_image,
  published_at,
  published_at_pretty,
  author,
  variant,
  ...props
}) => {
  const url = `/${slug}/`;

  return (
    <Article variant={variant} {...props}>
      <PostImageLink title={title} variant={variant} to={url}>
        <AspectRatioPreserver width={16} height={9}>
          <PostImage image={feature_image} />
        </AspectRatioPreserver>
      </PostImageLink>
      <PostContent variant={variant}>
        <GatsbyBlockLink to={url}>
          <PostHeader>
            <Heading as="h2" level="3">
              {title}
            </Heading>
          </PostHeader>
          <PostExcerpt>{excerpt}</PostExcerpt>
        </GatsbyBlockLink>
        <Author
          as="footer"
          src={author.profile_image}
          name={author.name}
          detail={<time dateTime={published_at}>{published_at_pretty}</time>}
        />
      </PostContent>
    </Article>
  );
};

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  feature_image: PropTypes.string,
  published_at: PropTypes.string.isRequired,
  published_at_pretty: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    profile_image: PropTypes.string.isRequired,
  }).isRequired,
  variant: variantPropType,
};
