module.exports = {
  // Used to make CMS urls relative in post body
  cmsUrl: `storybookblog`,
  // Used for Access-Control-Allow-Origin header in functions
  origin: `https://storybook.js.org`,
  // Site domain. Do not include a trailing slash!
  siteUrl: `https://storybook.js.org/blog`,
  domain: `https://storybook-blog.netlify.app/`,
  // Logo in /static dir used for SEO, RSS, and App manifest
  siteIcon: `storybook-favicon.png`,
  // Hiring banner
  hiring: true,
  // Used for App manifest e.g. Mobile Home Screen
  shortTitle: `Storybook`,
  // This allows an alternative site title for meta data for pages.
  siteTitleMeta: `Storybook Blog`,
  // This allows an alternative site description for meta data for pages.
  siteDescriptionMeta: `News and updates from the Storybook team`,
  // used on the home page
  tagLine: {
    header: `News and updates from the team`,
    footer: `News and updates from the team`,
  },
  // webmentions account
  webmentions: `storybook.js.org_blog`,
  // Mailing list CTAs copy
  mailingList: {
    id: `18b5cea6e6`,
    url: `https://storybook.us18.list-manage.com/subscribe/post`,
    server: `us18`,
    fields: {
      u: `06a6fce3ab1327784d4342396`,
      id: `18b5cea6e6`,
    },

    footer: `Get news, free tutorials, and Storybook tips emailed to you.`,
    banner: {
      title: `Join the Storybook mailing list`,
      body: `Get the latest news, updates and releases`,
    },
    post: {
      title: `Join the Storybook mailing list`,
      body: `Get the latest news, updates and releases`,
    },
    about: {
      title: ``,
      body: ``,
    },
  },
  // social media profiles
  socialMedia: {
    twitter: `https://twitter.com/storybookjs`,
  },
  // used to attribute applause to users
  USER_ID_KEY: 'storybook/user-id',
};
