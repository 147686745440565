import { css, styled } from '@storybook/theming';
import PropTypes from 'prop-types';

export const Container = styled.div`
  max-width: ${(props) => props.theme.widths[props.size]}px;

  ${(props) =>
    props.center &&
    css`
      && {
        margin-left: auto;
        margin-right: auto;
      }
    `}
`;

Container.propTypes = {
  size: PropTypes.oneOf(['narrow', 'regular', 'wide']),
  center: PropTypes.bool,
};

Container.defaultProps = {
  center: true,
};
