const storybookConfig = require('./siteConfig.storybook');
const inkConfig = require('./siteConfig.ink');

const publicationConfig =
  process.env.GATSBY_PUBLICATION === 'storybook' ? storybookConfig : inkConfig;

const baseConfig = {
  maxApplause: 15,
  postsPerPage: 14, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  shareImageWidth: 1000, // Change to the width of your default share image
  shareImageHeight: 523, // Change to the height of your default share image

  backgroundColor: `#e9e9e9`, // Used for Offline Manifest
  themeColor: `#15171A`, // Used for Offline Manifest

  chromaticTeam: [
    'https://twitter.com/chromaui',
    'https://twitter.com/storybookjs',
    'https://twitter.com/zqzoltan',
    'https://twitter.com/domyen',
    'https://twitter.com/tmeasday',
    'https://twitter.com/NorbertdeLangen',
    'https://twitter.com/mshilman',
    'https://twitter.com/GHengeveld',
    'https://twitter.com/kylesuss',
    'https://twitter.com/winkerVSbecks',
    'https://twitter.com/JamesLoomos',
    'https://twitter.com/xapaxa',
    'https://twitter.com/amandam_622',
    'https://twitter.com/jmhobbs',
    'https://twitter.com/coderkevin',
    'https://twitter.com/michaelarestad',
    'https://twitter.com/chromadeen',
    'https://twitter.com/chromamber',
    'https://twitter.com/cody_kaup',
    'https://twitter.com/chantastic',
    'https://twitter.com/kylegach',
    'https://twitter.com/Integrayshaun',
    'https://twitter.com/yannbf',
    'https://twitter.com/BRock97',
    'https://twitter.com/_jonok',
    'https://twitter.com/drreinhold',
    'https://twitter.com/KasperPeulen',
    'https://twitter.com/ReubenEllis15',
    'https://twitter.com/drreinhold/',
    'https://twitter.com/KasperPeulen',
    'https://twitter.com/elseloop/',
    'https://twitter.com/joevaugh4n/',
  ],
};

module.exports = {
  ...baseConfig,
  ...publicationConfig,
};
