import { useState } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import siteConfig from '../../utils/siteConfig';
import { logEvent } from '../../utils/custom-event';

const listUrl = siteConfig.mailingList.url;

export function useMailingListForm(sourceLocation) {
  const [hasSubmitted, setSubmitStatus] = useState(false);
  const onSubmitForm = async (values) => {
    const data = new FormData();
    const fullFields = {
      ...siteConfig.mailingList.fields,
      MERGE0: values.email,
    };

    Object.keys(fullFields).forEach((key) => data.append(key, fullFields[key]));

    await fetch(listUrl, {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    });

    logEvent('newsletter_subscribe', { source: sourceLocation });

    trackCustomEvent({
      category: 'newsletter',
      action: 'subscribe',
      label: sourceLocation,
      value: 1,
    });

    setSubmitStatus(true);
  };

  return [hasSubmitted, onSubmitForm];
}
