import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { Link, styles } from '@storybook/design-system';
import { TagItem } from './TagItem';

const TagListWrapper = styled.nav`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  margin-bottom: -10px;
`;

TagListWrapper.propTypes = {
  space: PropTypes.oneOf(['small', 'medium']),
};

const MoreTagsButton = styled(Link)`
  font-size: ${styles.typography.size.s2}px;
  padding-left: 5px;
  padding-right: 5px;

  &:focus {
    outline: auto;
  }
`;

export const TagList = ({ tags: rawTags, space, limit, ...props }) => {
  const tags = rawTags.filter((tag) => tag.visibility === 'public');
  const primaryTags = tags.slice(0, limit);
  const moreTags = tags.slice(limit);
  const [moreTagsVisible, setMoreTagsVisible] = React.useState(false);

  return (
    <TagListWrapper aria-label="Categories" space={space} {...props}>
      {primaryTags.map((tag) => (
        <TagItem key={tag.slug} to={`/tag/${tag.slug}/`}>
          {tag.name}
        </TagItem>
      ))}
      {moreTagsVisible &&
        moreTags.map((tag) => (
          <TagItem key={tag.slug} to={`/tag/${tag.slug}/`}>
            {tag.name}
          </TagItem>
        ))}
      {tags.length > 4 && !moreTagsVisible && (
        <MoreTagsButton
          isButton
          appearance="primary"
          onClick={() => setMoreTagsVisible(true)}
        >
          {`+ ${moreTags.length} more`}
        </MoreTagsButton>
      )}
    </TagListWrapper>
  );
};

export const TagsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    visibility: PropTypes.string,
  })
).isRequired;

TagList.propTypes = {
  tags: TagsPropType,
  space: PropTypes.oneOf(['small', 'medium']),
  limit: PropTypes.number,
};

TagList.defaultProps = { space: 'small', limit: 4, visibility: 'public' };
