import React from 'react';
import { styled } from '@storybook/theming';
import { Link, styles } from '@storybook/design-system';

export const HiringCTASmall = styled((props) => (
  <Link
    {...props}
    href="https://www.chromatic.com/company/jobs"
    rel="noopener noreferrer"
    target="_blank"
  >
    {'👩‍💻 Hiring now'}
  </Link>
))`
  font-weight: ${styles.typography.weight.bold};
  font-size: ${styles.typography.size.s1}px;
  font-size: ${styles.typography.size.s2}px;
  line-height: ${styles.typography.size.m2}px;
  padding: 3px 15px;
  background: ${styles.background.positive};
  color: ${styles.color.darkest};
  text-align: center;
  border: ${(props) => props.theme.borders.positive};
  border-radius: 4px;

  &:hover,
  &:active,
  &:focus {
    color: ${styles.color.darkest};
  }
`;
