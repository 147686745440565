import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { UserIdContext } from '../../utils/UserIdContext';
import { clamp, debounce } from '../../utils/utils';
import config from '../../utils/siteConfig';

const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

// https://docs.netlify.com/configure-builds/file-based-configuration/#deploy-contexts
const isProduction = process.env.CONTEXT === 'production';

const API_BASE_URL =
  (isProduction ? config.domain : '/') + '.netlify/functions';

const updateDatabase = debounce((slug, userId, newCount) => {
  if (newCount === 0) return;
  fetch(`${API_BASE_URL}/increment-applause`, {
    method: 'POST',
    body: JSON.stringify({
      slug,
      userId,
      count: newCount,
    }),
  }).catch((error) => console.log(error.message));
}, 500);

export const ApplauseContext = createContext();

export const ApplauseProvider = ({ slug, webMentionsCount = 0, children }) => {
  const [userApplause, setUserApplause] = useState(0);
  const [otherApplause, setOtherApplause] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userId] = useContext(UserIdContext);

  useEffect(() => {
    updateDatabase(slug, userId, userApplause);
  }, [userApplause]);

  const onApplause = () => {
    setUserApplause((prevUserApplause) =>
      clamp(prevUserApplause + 1, 0, config.maxApplause)
    );
  };

  const value = useMemo(
    () => [
      userApplause + otherApplause + webMentionsCount,
      onApplause,
      isLoading,
    ],
    [userApplause, otherApplause, onApplause, isLoading]
  );

  const fetchApplauseWithRetry = (retryCount = 0) => fetch(`${API_BASE_URL}/get-applause?slug=${slug}&userId=${userId}`)
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);

        if (typeof json.total === 'number') {
          setUserApplause(json.fromUser);
          setOtherApplause(json.total - json.fromUser);
        }
      })
      .then(null, (error) => {
        if (retryCount < 4) {
          return delay(1000 * retryCount).then(() =>
            fetchApplauseWithRetry(retryCount + 1)
          );
        } else {
          throw error;
        }
      });

  useEffect(() => {
    if (slug === undefined || userId === undefined) return;

    fetchApplauseWithRetry().catch((error) => {
      setIsLoading(false);
      console.log(error.message);
    });
  }, [slug, userId]);

  return (
    <ApplauseContext.Provider value={value}>
      {children}
    </ApplauseContext.Provider>
  );
};

ApplauseProvider.propTypes = {
  slug: PropTypes.string.isRequired,
  webMentionsCount: PropTypes.number.isRequired,
};
