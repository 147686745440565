import React from 'react';
import PropTypes from 'prop-types';
import { css, Global, styled } from '@storybook/theming';
import { Helmet } from 'react-helmet';
import { global, styles } from '@storybook/design-system';
import {
  LinksContextProvider,
  defaultLinks,
} from '@storybook/components-marketing';
import { useStaticQuery, graphql } from 'gatsby';
import '@docsearch/css';
import { Header } from './Header';
import { Footer } from './Footer';
import { ThemeProvider } from '../styles/theme';
import { UserIdProvider } from '../utils/UserIdContext';
import siteConfig from '../utils/siteConfig';
import { GatsbyLinkWrapper } from './GatsbyLink';

const { bodyStyles, fontUrl } = global;

const globalStyle = css`
  body {
    ${bodyStyles}
  }
`;

const Main = styled.main`
  ${(props) => props.includePageMargins && styles.pageMargins};
  padding-top: ${(props) => (props.minimalMode ? '2rem' : '5rem')};
  padding-bottom: ${(props) => (props.minimalMode ? '0' : '5rem')};
`;

const navLinks = {
  ...defaultLinks,
  blog: {
    url: '/',
    linkWrapper: GatsbyLinkWrapper,
  },
};

/**
 * Main layout component
 *
 * The Layout component wraps around each page.
 * It also provides the header, footer and the main
 * styles.
 *
 */
export const PureLayout = ({
  subNav,
  children,
  bodyClass,
  includePageMargins,
  dxData,
  minimalMode,
}) => (
  <LinksContextProvider value={navLinks}>
    <UserIdProvider>
      <ThemeProvider>
        <>
          <Global styles={globalStyle} />
          <Helmet>
            <html lang="en" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com/"
              crossOrigin
            />
            <link href={fontUrl} rel="stylesheet" />
            <link href="mailto:varun@chromatic.com" rel="me" />
            <link
              rel="webmention"
              href={`https://webmention.io/${siteConfig.webmentions}/webmention`}
            />
            <link
              rel="pingback"
              href={`https://webmention.io/${siteConfig.webmentions}/xmlrpc`}
            />
            <body className={bodyClass} />
          </Helmet>
          {!minimalMode && (
            <Header
              hiring={siteConfig.hiring}
              githubStars={dxData.githubStars}
              versionString={dxData.latestVersion}
            />
          )}
          {subNav}
          {/* All the main content gets inserted here, index.js, post.js */}
          <Main
            includePageMargins={includePageMargins}
            minimalMode={minimalMode}
          >
            {children}
          </Main>
          {!minimalMode && <Footer subscriberCount={dxData.subscriberCount} />}
        </>
      </ThemeProvider>
    </UserIdProvider>
  </LinksContextProvider>
);

PureLayout.propTypes = {
  subNav: PropTypes.node,
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  includePageMargins: PropTypes.bool,
  dxData: PropTypes.any,
  minimalMode: PropTypes.any,
};

PureLayout.defaultProps = {
  includePageMargins: true,
};

export function Layout(props) {
  const { dxData } = useStaticQuery(graphql`
    query DXQuery {
      dxData {
        subscriberCount
        githubStars
        latestVersion
      }
    }
  `);

  return <PureLayout dxData={dxData} {...props} />;
}
