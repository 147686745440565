import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import {
  Avatar,
  WithTooltip,
  TooltipNote,
  styles,
} from '@storybook/design-system';
import { useMedia } from 'react-use';
import { Mention } from './Mention';

const { color, typography } = styles;

const UserAvatar = styled(Avatar)`
  box-shadow: ${color.lightest} 0 0 0 2px;
  display: block;
  background-color: white;
`;

const UserTooltipWrapper = styled(WithTooltip)``;

const UserEllipses = styled.li`
  display: inline-flex;
  font-size: ${typography.size.s1}px;
  color: ${color.mediumdark};
  margin-left: 6px;
  white-space: nowrap;
`;

const User = styled.li`
  display: inline-flex;
`;

const Users = styled.ul`
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  vertical-align: top;
  margin: 0;
  padding: 0;
  list-style: none;

  ${User} {
    position: relative;
    &:not(:first-child) {
      margin-left: -6px;
    }
  }
`;

export const InteractionsList = ({
  mentions: mentions = [
    { id: 'loading', image: null, name: 'loading' },
    { id: 'loading2', image: null, name: 'loading' },
    { id: 'loading3', image: null, name: 'loading' },
  ],
  size = 'medium',
  limit = 12,
  ...props
}) => {
  const count = mentions.length;
  const isWide = useMedia('(min-width: 600px)');
  const _limit = isWide ? limit : 4;

  return (
    <Users aria-label="users" {...props}>
      {mentions.slice(0, _limit).map(({ id, name, image }) => (
        <User key={id}>
          <UserTooltipWrapper
            hasChrome={false}
            placement="bottom"
            trigger="hover"
            tooltip={<TooltipNote note={name} />}
          >
            <UserAvatar size={size} username={name} src={image} />
          </UserTooltipWrapper>
        </User>
      ))}
      {count > _limit && (
        <UserEllipses aria-label={`${count - _limit} more user(s)`}>
          {' '}
          &#43; {count - _limit}{' '}
        </UserEllipses>
      )}
    </Users>
  );
};

InteractionsList.propTypes = {
  mentions: PropTypes.arrayOf(PropTypes.shape({ ...Mention.propTypes }))
    .isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small', 'tiny']).isRequired,
  limit: PropTypes.number,
};
