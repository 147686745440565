import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { Author } from '../Author';
import { Heading } from '../Heading';
import { GatsbyBlockLink } from '../GatsbyLink';
import { PostExcerpt } from './PostExcerpt';
import { PostHeader } from './PostHeader';
import { AspectRatioPreserver } from '../AspectRatioPreserver';
import { PostImage } from './PostImage';

const { color, breakpoint } = styles;

const PostImageLink = styled(GatsbyBlockLink)``;

const PostContent = styled.div`
  margin-top: ${(props) => props.theme.space.medium}px;

  @media (min-width: ${breakpoint * 1.3}px) {
    margin-top: 0;
    margin-left: 40px;
  }
`;

const Date = styled.time`
  color: ${color.darkest};
  margin-bottom: 24px;
  margin-right: 20px;
  font-size: ${styles.typography.size.s3}px;
  line-height: ${styles.typography.size.s3}px;
  white-space: nowrap;
`;

const Article = styled.article`
  display: grid;
  grid-template-columns: 1fr ${(props) => props.theme.grid.column}px;
  border-top: 1px solid ${styles.color.border};
  padding-top: 32px;
  padding-bottom: 32px;

  :first-of-type {
    border-top: 0;
  }

  > * {
    grid-column: 1 / -1;

    @media (min-width: ${breakpoint * 1.3}px) {
      grid-column: auto;
    }
  }

  @media (min-width: ${breakpoint * 1.3}px) {
    grid-template-columns: minmax(min-content, 15%) 1fr 35%;
  }
`;

export const ReleasePostCard = ({
  title,
  excerpt,
  slug,
  feature_image,
  published_at,
  published_at_pretty,
  author,
  ...props
}) => {
  const url = `/${slug}/`;

  return (
    <Article {...props}>
      <Date dateTime={published_at}>{published_at_pretty}</Date>
      <PostImageLink to={url}>
        <AspectRatioPreserver width={16} height={9}>
          <PostImage image={feature_image} />
        </AspectRatioPreserver>
      </PostImageLink>
      <PostContent>
        <GatsbyBlockLink to={url}>
          <PostHeader>
            <Heading as="h2" level="3">
              {title}
            </Heading>
          </PostHeader>
          <PostExcerpt>{excerpt}</PostExcerpt>
        </GatsbyBlockLink>
        <Author
          as="footer"
          src={author.profile_image}
          name={author.name}
          detail={null}
          variant="minimal"
        />
      </PostContent>
    </Article>
  );
};

ReleasePostCard.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  feature_image: PropTypes.string,
  published_at: PropTypes.string.isRequired,
  published_at_pretty: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    profile_image: PropTypes.string.isRequired,
  }).isRequired,
};
