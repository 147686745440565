import React from 'react';
import PropTypes from 'prop-types';
import { Footer as MarketingFooter } from '@chromatic-com/tetra';

export const Footer = ({ theme = 'light' }) => (
  <MarketingFooter theme={theme} />
);

Footer.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};
