import { useState, useEffect } from 'react';

export function usePersistedState(defaultValue, key) {
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    if (window.location !== window.parent.location) {
      setValue(defaultValue);
    } else {
      const stickyValue = window.localStorage.getItem(key);

      try {
        if (typeof stickyValue === 'string' && stickyValue !== 'undefined') {
          setValue(JSON.parse(stickyValue));
        } else {
          setValue(defaultValue);
        }
      } catch (e) {
        setValue(defaultValue);
      }
    }
  }, []);

  useEffect(() => {
    if (window.location === window.parent.location) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
}
