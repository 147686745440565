import { useState, useEffect } from 'react';
import siteConfig from '../../utils/siteConfig';

const API_URL = '//webmention.io/api/mentions.jf2';
// types: 'in-reply-to', 'like-of', 'repost-of', 'bookmark-of', 'mention-of', 'rsvp',

export const useWebMentions = (target) => {
  const [feed, setFeed] = useState({
    responses: [],
    interactions: [],
    count: 0,
  });

  useEffect(() => {
    if (target === undefined) return;

    fetch(
      `${API_URL}?token=${process.env.WEBMENTION_API_KEY}&target=${target}&sort-by=published`
    )
      .then((res) => res.json())
      .then((json) => [
        filterOutChromaticTeam(json.children),
        json.children.length,
      ])
      .then(([rawFeed, count]) => {
        const categorizedFeed = categorizeFeed(rawFeed, count);
        setFeed(categorizedFeed);
      })
      .catch((error) => console.log(error.message));
  }, []);

  return feed;
};

export const categorizeFeed = (feed, count) =>
  feed.reduce(
    (acc, item) => {
      if (item['wm-property'] === 'in-reply-to' && item.content) {
        acc.responses.push({
          id: item['wm-id'],
          name: item.author.name,
          image: item.author.photo,
          published: item.published,
          content: item.content.text,
        });
      }

      acc.interactions.push({
        id: item['wm-id'],
        name: item.author.name,
        image: item.author.photo,
      });

      return acc;
    },
    {
      responses: [],
      interactions: [],
      count: count,
    }
  );

function filterOutChromaticTeam(feed) {
  return feed.filter(
    (mention) => !siteConfig.chromaticTeam.includes(mention?.author?.url)
  );
}
