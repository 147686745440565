import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { PostCard } from './PostCard';
import { MailingListBanner } from './MailingList';
import siteConfig from '../utils/siteConfig';

export const PostSectionList = styled.section`
  margin-top: 40px;
  margin-bottom: ${(props) => props.theme.space.large}px;
  display: grid;
  grid-gap: ${(props) => props.theme.grid.gap}px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  @media (min-width: ${styles.breakpoint * 1.5}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  article:first-child {
    grid-column: 1 / -1;
  }
`;

const sectionCount = siteConfig.postsPerPage / 2;

export const PostGrid = ({ posts, subscriberCount }) => {
  const postsChunk1 = posts.slice(0, sectionCount);
  const postsChunk2 = posts.slice(sectionCount);

  return (
    <>
      <PostSectionList>
        {postsChunk1.map(({ node }, idx) => (
          <PostCard
            key={node.id}
            variant={idx === 0 ? 'large' : 'small'}
            {...node}
          />
        ))}
      </PostSectionList>
      <MailingListBanner subscriberCount={subscriberCount} />
      {postsChunk2.length > 0 && (
        <PostSectionList>
          {postsChunk2.map(({ node }, idx) => (
            <PostCard
              key={node.id}
              variant={idx === 0 ? 'large' : 'small'}
              {...node}
            />
          ))}
        </PostSectionList>
      )}
    </>
  );
};

PostGrid.propTypes = {
  posts: PropTypes.array.isRequired,
  subscriberCount: PropTypes.number.isRequired,
};
