import React from 'react';
import PropTypes from 'prop-types';
import { css, styled } from '@storybook/theming';
import { Avatar, styles } from '@storybook/design-system';

const { color, typography } = styles;
const AuthorVariantType = PropTypes.oneOf(['default', 'minimal', 'emphasis']);

const AvatarWrapper = styled((props) => <Avatar {...props} size="large" />)`
  ${(props) =>
    (props.variant === 'default' || props.variant === 'minimal') &&
    css`
      width: 32px;
      height: 32px;
      line-height: 32px;
    `}
`;

AvatarWrapper.propTypes = {
  variant: AuthorVariantType,
};

const AuthorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin-left: 15px;
`;

const Name = styled.div`
  color: ${color.darkest};
  font-size: ${typography.size.s2}px;
  line-height: ${typography.size.s3}px;
  font-weight: ${typography.weight.regular};
  hyphens: auto;

  ${(props) =>
    props.variant === 'default' &&
    css`
      margin-top: 2px;
    `}

  ${(props) =>
    props.variant === 'emphasis' &&
    css`
      font-weight: ${typography.weight.bold};
    `}
`;

Name.propTypes = {
  variant: AuthorVariantType,
};

const Detail = styled.div`
  color: ${color.dark};
  font-size: ${typography.size.s1}px;
  line-height: ${typography.size.s3}px;
  margin-top: 2px;
`;

export const Author = ({ detail, name, src, variant, ...rest }) => (
  <AuthorWrapper {...rest}>
    <AvatarWrapper variant={variant} src={src} alt="" />

    <ContentContainer>
      {variant === 'minimal' ? (
        <Name variant="minimal">{name}</Name>
      ) : (
        <>
          <Name variant={variant}>{name}</Name>
          <Detail>{detail}</Detail>
        </>
      )}
    </ContentContainer>
  </AuthorWrapper>
);

Author.propTypes = {
  detail: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  variant: AuthorVariantType,
};

Author.defaultProps = {
  variant: 'default',
};
