import { styled } from '@storybook/theming';
import { htmlFormatting } from '../../styles/html-formatting';

export const PostBody = styled.div`
  ${htmlFormatting}
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  dl,
  pre,
  blockquote,
  hr,
  details,
  .footnotes {
    min-width: 100%;
  }
`;
