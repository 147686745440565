import { css, styled } from '@storybook/theming';
import PropTypes from 'prop-types';
import { styles } from '@storybook/design-system';

const { color, typography } = styles;

export const Heading = styled.h1`
  color: ${color.darkest};
  margin-top: 0;
  margin-bottom: 0;
  font-weight: ${typography.weight.bold};

  ${(props) =>
    props.level === '1' &&
    css`
      font-size: ${typography.size.l2}px;
      line-height: ${typography.size.l3}px;
      letter-spacing: -0.56px;
    `};
  ${(props) =>
    props.level === '2' &&
    css`
      font-size: ${typography.size.m1}px;
      line-height: ${typography.size.m3}px;
    `};
  ${(props) =>
    props.level === '3' &&
    css`
      font-size: ${typography.size.m1}px;
      line-height: ${typography.size.m2}px;
    `};
  ${(props) =>
    props.level === '4' &&
    css`
      font-size: ${typography.size.s3}px;
      line-height: ${typography.size.m1}px;
    `};
`;

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf(['1', '2', '3', '4']).isRequired,
};
