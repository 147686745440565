import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Button, Icon } from '@storybook/design-system';
import { Layout } from '../components/Layout';
import { Container } from '../components/Container';
import { PageHeader } from '../components/PageHeader';
import { ReleasePostCard } from '../components/PostCard';
import { MetaData } from '../components/MetaData';

const ReleasesPage = ({ data, location }) => {
  const posts = data.allGhostPost.edges;

  return (
    <Layout>
      <MetaData location={location} title="Changelog" />
      <Container size="wide">
        <PageHeader
          title="Changelog"
          subtitle={
            <>
              We build open source and cloud tools for UI developers.{' '}
              <Button
                isLink
                appearance="outline"
                size="small"
                tertiary
                containsIcon
                target="_blank"
                href="/blog/rss-releases"
              >
                <Icon icon="rss" aria-label="RSS Feed" />
              </Button>
            </>
          }
        />
        <section>
          {posts.map(({ node }) => (
            <ReleasePostCard key={node.id} {...node} />
          ))}
        </section>
      </Container>
    </Layout>
  );
};

ReleasesPage.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
    mailingList: PropTypes.shape({
      subscriberCount: PropTypes.number,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const query = graphql`
  query ReleasesPageQuery {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: {
        tags: {
          elemMatch: { slug: { eq: "hash-release", nin: ["hash-preview"] } }
        }
      }
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
    mailingList {
      subscriberCount
    }
  }
`;

export default ReleasesPage;
