import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { Avatar, styles } from '@storybook/design-system';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const { typography, color } = styles;

const User = styled.div`
  display: flex;
`;

const UserAvatar = styled(Avatar)`
  height: 36px;
  width: 36px;
  margin-right: 20px;
  margin-top: 5px;
  background-color: white;
`;

const UserDescription = styled.div`
  flex: 1;
  font-size: ${typography.size.s3}px;
  line-height: ${typography.size.m3}px;
  color: ${color.dark};
`;

const UsersHeading = styled.div`
  color: ${color.darkest};
  font-size: ${typography.size.s3}px;
  line-height: ${typography.size.m2}px;
  font-weight: ${typography.weight.extrabold};

  time {
    font-weight: ${typography.weight.regular};
    color: ${color.dark};
  }
`;

export const Mention = ({ name, image, content, published }) => {
  const timeSincePublished = React.useMemo(
    () =>
      published
        ? formatDistanceToNow(new Date(published), { addSuffix: true })
        : '',
    [published]
  );

  return (
    <User>
      <UserAvatar src={image} size="large" />

      <UserDescription>
        <UsersHeading data-chromatic="ignore">
          {name}{' '}
          {published && <time dateTime={published}>{timeSincePublished}</time>}
        </UsersHeading>

        {content && <div>{content}</div>}
      </UserDescription>
    </User>
  );
};

Mention.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  published: PropTypes.string,
  content: PropTypes.string,
};
