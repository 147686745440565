import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { Button, Icon } from '@storybook/design-system';
import { Stack } from './Stack';
import { TagList, TagsPropType } from './TagList';
import { Search } from './Search';
import siteConfig from '../utils/siteConfig';

const MetaBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  nav {
    margin-bottom: -${(props) => props.theme.space.small}px;
    margin-right: ${(props) => props.theme.grid.gap}px;
    flex: 1 1 auto;
  }
`;

const ControlsContainer = styled(Stack)`
  margin-left: 10px;
  flex: none;
  max-width: ${(props) => props.theme.grid.column}px;
`;

const SearchContainer = styled.div`
  flex: none;
  max-width: ${(props) => props.theme.grid.column}px;
`;

export const MetaBar = ({ tags, posts }) => (
  <MetaBarWrapper>
    <SearchContainer axis="horizontal">
      <Search posts={posts} />
    </SearchContainer>
    <TagList tags={tags} />
    <ControlsContainer axis="horizontal" space={10}>
      {process.env.GATSBY_PUBLICATION === 'ink' && (
        <Button
          isLink
          appearance="outline"
          size="small"
          containsIcon
          href={siteConfig.socialMedia.twitter}
          target="_blank"
          rel="me noopener noreferrer"
        >
          <Icon icon="twitter" aria-label="twitter" />
        </Button>
      )}
      <Button
        isLink
        appearance="outline"
        size="small"
        tertiary
        containsIcon
        target="_blank"
        href="/blog/rss/"
      >
        <Icon icon="rss" aria-label="RSS Feed" />
      </Button>
    </ControlsContainer>
  </MetaBarWrapper>
);

MetaBar.propTypes = {
  tags: TagsPropType,
  posts: PropTypes.array.isRequired,
};
