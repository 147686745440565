import { styled } from '@storybook/theming';
import PropTypes from 'prop-types';

const alignmentType = PropTypes.oneOf(['start', 'end', 'center']);

const getAlignment = (value) =>
  value === 'start' || value === 'end' ? 'flex-' + value : value;

export const Stack = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.axis === 'horizontal' ? 'row' : 'column'};
  justify-content: ${(props) =>
    props.space === 'evenly'
      ? 'space-between'
      : getAlignment(props.distribution)};
  align-items: ${(props) => getAlignment(props.alignment)};

  /* higher specificity to target component level margin values */
  && {
    > * {
      ${(props) => {
        const space = props.theme.space[props.space]
          ? props.theme.space[props.space]
          : props.space;
        return space === 'evenly'
          ? {}
          : {
              [props.axis === 'horizontal' ? 'marginRight' : 'marginBottom']:
                space,
            };
      }}
    }
    > *:last-child {
      ${(props) => {
        return {
          [props.axis === 'horizontal' ? 'marginRight' : 'marginBottom']: 0,
        };
      }}
    }
  }
`;

Stack.propTypes = {
  axis: PropTypes.oneOf(['horizontal', 'vertical']),
  space: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alignment: alignmentType,
  distribution: PropTypes.oneOf(['start', 'end', 'center', 'space-between']),
};

Stack.defaultProps = {
  axis: 'vertical',
  space: 'small',
};
