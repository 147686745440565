const reframe = require('reframe.js').default;
const { handleVideoPlayer } = require('./src/utils/video');

/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */
var trustAllScripts = function () {
  var scriptNodes = document.querySelectorAll('.load-external-scripts script');

  for (var i = 0; i < scriptNodes.length; i += 1) {
    var node = scriptNodes[i];
    var s = document.createElement('script');
    s.type = node.type || 'text/javascript';

    var gistRegex = /gist\.github\.com/gm;

    if (node.attributes.src && gistRegex.test(node.attributes.src.value)) {
      loadGist(node.attributes.src.value, node);
    } else {
      if (node.attributes.src) {
        s.src = node.attributes.src.value;
      } else {
        s.innerHTML = node.innerHTML;
      }
    }

    document.getElementsByTagName('head')[0].appendChild(s);
  }
};

function loadGist(gistUrl, node) {
  const gistRegex = /gist\.github\.com\/.+\/(.*?)\../gm;
  const gistId = gistRegex.exec(gistUrl)[1];

  const gistEl = document.createElement('iframe');
  gistEl.className = 'kg-embed-gist';
  node.replaceWith(gistEl);

  let doc = gistEl.document;
  if (gistEl.contentDocument) doc = gistEl.contentDocument;
  else if (gistEl.contentWindow) doc = gistEl.contentWindow.document;

  const gistScript = `<script type="text/javascript" src="${gistUrl}"></script>`;
  const styles = '<style>*{font-size:12px;}</style>';
  const elementId = `gist-${gistId}`;
  gistEl.id = elementId;
  const resizeScript = `onload="parent.document.getElementById('${elementId}').style.height=document.body.scrollHeight + 'px'"`;
  const iframeHtml = `<html><head><base target="_parent">${styles}</head><body ${resizeScript}>${gistScript}</body></html>`;

  doc.open();
  doc.writeln(iframeHtml);
  doc.close();
}

function resizeImagesInGalleries() {
  var images = document.querySelectorAll('.kg-gallery-image img');
  images.forEach(function (image) {
    var container = image.closest('.kg-gallery-image');
    var width = image.attributes.width.value;
    var height = image.attributes.height.value;
    var ratio = width / height;
    container.style.flex = ratio + ' 1 0%';
  });
}

function responsiveVideos() {
  reframe(
    [
      'iframe[src*="player.vimeo.com"]',
      'iframe[src*="youtube.com"]',
      'iframe[src*="youtube-nocookie.com"]',
      'iframe[src*="kickstarter.com"][src*="video.html"]',
      'iframe[src*="gfycat.com"]',
      'object',
      'embed',
    ].join(', ')
  );
}

function initializeVideoCards() {
  const videoCardElements = document.querySelectorAll('.kg-video-card');

  for (let i = 0; i < videoCardElements.length; i++) {
    handleVideoPlayer(videoCardElements[i]);
  }
}

exports.onRouteUpdate = function () {
  trustAllScripts();
  responsiveVideos();
  resizeImagesInGalleries();
  initializeVideoCards();

  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('set', 'content_group', 'blog');
  }
};
