export const joinCommunityItems = [
  {
    icon: 'github',
    href: 'https://github.com/storybookjs',
    label: 'Github',
  },
  {
    icon: 'discord',
    href: 'https://discord.gg/storybook',
    label: 'Discord',
  },
  {
    icon: 'twitter',
    href: 'https://twitter.com/storybookjs',
    label: 'Twitter',
  },
  {
    icon: 'youtube',
    href: 'https://www.youtube.com/channel/UCr7Quur3eIyA_oe8FNYexfg',
    label: 'Youtube',
  },
];
