import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import {
  SubNav,
  SubNavTabs,
  SubNavRight,
  SubNavLinkList,
} from '@storybook/components-marketing';
import { Layout } from '../components/Layout';
import { Container } from '../components/Container';
import { PageHeader } from '../components/PageHeader';
import { MetaBar } from '../components/MetaBar';
import { Pagination } from '../components/Pagination';
import { PostGrid } from '../components/PostGrid';
import { MetaData } from '../components/MetaData';
import siteConfig from '../utils/siteConfig';
import { GatsbyLinkWrapper } from '../components/GatsbyLink';
import { joinCommunityItems } from '../utils/joinCommunityItems';

const communityItems = [
  {
    key: '0',
    label: 'Get involved',
    href: 'https://storybook.js.org/community',
  },
  {
    key: '1',
    label: 'Blog',
    href: '/',
    LinkWrapper: GatsbyLinkWrapper,
    isActive: true,
  },
];

const HomePage = ({ data, pageContext, location }) => {
  const posts = data.allGhostPost.edges;
  const tags = data.allGhostTag.nodes;

  return (
    <Layout
      subNav={
        process.env.GATSBY_PUBLICATION === 'storybook' && (
          <SubNav>
            <SubNavTabs label="Blog nav" items={communityItems} />
            <SubNavRight>
              <SubNavLinkList
                label="Join the community:"
                items={joinCommunityItems}
              />
            </SubNavRight>
          </SubNav>
        )
      }
    >
      <MetaData location={location} title="Articles" />
      <Container size="wide">
        <PageHeader
          title={siteConfig.tagLine.header}
          controls={<MetaBar tags={tags} posts={pageContext.searchData} />}
        />
        <PostGrid
          posts={posts}
          subscriberCount={data.mailingList.subscriberCount}
        />
        <Pagination
          pageContext={pageContext}
          subscriberCount={data.mailingList.subscriberCount}
        />
      </Container>
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
    mailingList: PropTypes.shape({
      subscriberCount: PropTypes.number,
    }).isRequired,
    allGhostTag: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export const query = graphql`
  query HomePageQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
      filter: {
        tags: { elemMatch: { slug: { nin: ["data-schema", "hash-preview"] } } }
      }
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
    mailingList {
      subscriberCount
    }
    allGhostTag(
      sort: { order: DESC, fields: postCount }
      filter: { visibility: { eq: "public" }, slug: { ne: "data-schema" } }
    ) {
      nodes {
        ...GhostTagFields
      }
    }
  }
`;

export default HomePage;
