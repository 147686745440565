import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@storybook/theming';

const theme = {
  space: { small: 12, medium: 24, large: 48 },
  borders: { positive: '1px solid rgba(102,191,60,0.2)' },
  widths: {
    narrow: 660,
    regular: 1060,
    wide: 1200,
  },
  grid: {
    gap: 50,
    column: 320,
  },
};

export const ThemeProvider = ({ children }) => (
  <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
);
