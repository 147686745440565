import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Clipboard,
  TooltipNote,
  WithTooltip,
} from '@storybook/design-system';
import { Stack } from './Stack';

export const ShareLinks = ({ shareText, link, authorTwitter, axis }) => {
  const tweetContent = `${shareText} by ${authorTwitter} ${link}`;

  return (
    <Stack axis={axis} space={16} alignment="start">
      <WithTooltip
        hasChrome={false}
        tooltip={<TooltipNote note="Share on Twitter" />}
      >
        <Button
          isLink
          appearance="outline"
          size="medium"
          containsIcon
          href={`https://twitter.com/intent/tweet?text=${tweetContent}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="twitter" aria-hidden />
        </Button>
      </WithTooltip>
      <WithTooltip
        hasChrome={false}
        tooltip={<TooltipNote note="Share on Facebook" />}
      >
        <Button
          isLink
          appearance="outline"
          size="medium"
          containsIcon
          href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
            link
          )}&t=${shareText}`}
          onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon="facebook" aria-hidden />
        </Button>
      </WithTooltip>
      <Button
        ButtonWrapper={Clipboard}
        appearance="outline"
        size="medium"
        containsIcon
        toCopy={link}
        renderCopiedTooltip={() => <TooltipNote note="Copied" />}
        renderUncopiedTooltip={() => <TooltipNote note="Copy link" />}
      >
        <Icon icon="link" aria-hidden />
      </Button>
    </Stack>
  );
};

ShareLinks.propTypes = {
  shareText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  authorTwitter: PropTypes.string.isRequired,
  axis: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

ShareLinks.defaultProps = {
  axis: 'vertical',
};
