import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { MailingListSubscriberCount } from './MailingListSubscriberCount';
import { MailingListSignup } from './MailingListSignup';
import { Stack } from '../Stack';
import siteConfig from '../../utils/siteConfig';

const FormWrapper = styled(Stack)`
  min-width: 240px;
`;

const CopyWrapper = styled(Stack)`
  text-align: center;

  @media (min-width: ${styles.breakpoint * 1}px) {
    text-align: left;
    margin-right: ${(props) => props.theme.space.medium}px;
  }

  @media (min-width: ${styles.breakpoint * 1.5}px) {
    max-width: 34em;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-top: 1px solid ${styles.color.border};
  border-bottom: 1px solid ${styles.color.border};
  margin-bottom: 40px;

  ${CopyWrapper} {
    margin-bottom: ${(props) => props.theme.space.medium}px;
  }

  @media (min-width: ${styles.breakpoint * 1}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${CopyWrapper} {
      margin-bottom: ${(props) => props.theme.space.medium}px;
      margin-bottom: 0;
    }
  }
`;

const BannerHeading = styled(Heading)`
  background-color: #e000d8;
  background: radial-gradient(
      circle at -10%,
      rgba(65, 121, 217, 0.9) 0%,
      rgba(162, 87, 185, 0) 100%
    ),
    linear-gradient(270deg, #e000d8 0%, #009aff 100%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

export const MailingListBanner = ({ subscriberCount }) => (
  <BannerContainer>
    <CopyWrapper space={4}>
      <BannerHeading as="h3" level="2">
        {siteConfig.mailingList.banner.title}
      </BannerHeading>
      <Text>{siteConfig.mailingList.banner.body}</Text>
    </CopyWrapper>
    <FormWrapper space="small" alignment="center">
      <MailingListSignup sourceLocation="post-grid" />
      <MailingListSubscriberCount count={subscriberCount} />
    </FormWrapper>
  </BannerContainer>
);

MailingListBanner.propTypes = {
  subscriberCount: PropTypes.number.isRequired,
};

MailingListBanner.defaultProps = {
  title: 'Join the newsletter',
};
