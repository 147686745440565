import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { Link, styles, Highlight } from '@storybook/design-system';
import { Container } from '../Container';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { Author } from '../Author';
import { MailingListCTA } from '../MailingList';
import { HiringCTA } from '../Hiring';
import { Stack } from '../Stack';
import { TagList, TagsPropType } from '../TagList';
import { PostSidebar } from './PostSidebar';
import { PostBody } from './PostBody';
import { WebMentions, useWebMentions } from '../WebMentions';
import { ApplauseProvider } from '../Applause';
import { useWideContentVisible } from '../../utils/useWideContentVisible';
import siteConfig from '../../utils/siteConfig';
import { cmsToRelativeLinks } from '../../utils/cmsToRelativeLinks';

const { color, breakpoint, typography } = styles;

const Article = styled(Container)`
  padding-bottom: ${(props) => (props.minimalMode ? 0 : '64px')};
`;

const SidebarWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
  transition: opacity 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: ${(props) => (props.wideContentVisible ? 0 : 1)};

  @media (min-width: ${breakpoint * 1.75}px) {
    display: block;
  }
`;

SidebarWrapper.propTypes = {
  wideContentVisible: PropTypes.bool.isRequired,
};

export const FeatureImage = styled.figure`
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 3rem;

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
`;

FeatureImage.propTypes = {
  image: PropTypes.string,
};

const PostInner = styled.div`
  position: relative;
`;

const Excerpt = styled((props) => <Text {...props} variant="lead" />)`
  margin-top: ${(props) => props.theme.space.small}px;
  margin-bottom: 26px;
`;

const Byline = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > * {
    margin-right: 40px;
    margin-bottom: 20px;
  }

  > *:last-child {
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const UpdatedAt = styled.div`
  display: inline-block;
  background: ${color.lighter};
  color: ${color.dark};
  font-size: ${typography.size.s1}px;
  line-height: ${typography.size.s3}px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 10px;
`;

const ArticleFooter = styled(Stack)`
  padding-top: ${(props) => props.theme.space.large}px;
  padding-bottom: ${(props) => props.theme.space.large}px;
  border-bottom: 1px solid ${color.border};
  margin-bottom: 40px;
`;

const Header = styled.header`
  ${(props) => props.minimalMode && `margin-bottom: 24px;`}
`;

export const Post = ({
  published_at_pretty,
  published_at,
  updated_at_pretty,
  updated_at,
  title,
  excerpt,
  feature_image,
  html,
  authors,
  tags,
  subscriberCount,
  slug,
  minimalMode,
}) => {
  const postBodyRef = useRef(null);

  const wideContentVisible = useWideContentVisible(
    postBodyRef,
    '.kg-gallery-container, .kg-width-wide, .kg-width-full',
    {
      root: null,
      rootMargin: '0px 0px -40% 0px',
    }
  );

  const url = `${siteConfig.siteUrl}/${slug}/`;

  const webMentionsFeed = useWebMentions(url);

  const postHtml = cmsToRelativeLinks(html);

  return (
    <ApplauseProvider slug={slug} webMentionsCount={webMentionsFeed.count}>
      <Article as="article" minimalMode={minimalMode} size="wide">
        <FeatureImage>
          <img src={feature_image} alt="" />
        </FeatureImage>

        <PostInner>
          {!minimalMode && (
            <SidebarWrapper wideContentVisible={wideContentVisible}>
              <PostSidebar
                link={url}
                shareText={`${title} — ${excerpt}`}
                authorTwitter={authors[0].twitter}
              />
            </SidebarWrapper>
          )}

          <Container size="narrow">
            <Header minimalMode={minimalMode}>
              <Heading as="h1" level="1">
                {title}
              </Heading>
              {!minimalMode && (
                <>
                  <Excerpt>{excerpt}</Excerpt>
                  <Byline>
                    {authors.map((author) => (
                      <Author
                        variant="emphasis"
                        key={author.id}
                        src={author.profile_image}
                        name={author.name}
                        detail={
                          <>
                            <time dateTime={published_at}>
                              {published_at_pretty}
                            </time>{' '}
                            —{' '}
                            <Link
                              href={`https://twitter.com/${author.twitter}`}
                              tertiary
                            >
                              {author.twitter}
                            </Link>
                            {updated_at_pretty && authors.length === 1 && (
                              <>
                                <UpdatedAt>
                                  Last updated:{' '}
                                  <time dateTime={updated_at}>
                                    {updated_at_pretty}
                                  </time>
                                </UpdatedAt>
                              </>
                            )}
                          </>
                        }
                      />
                    ))}
                  </Byline>
                </>
              )}
            </Header>

            <Highlight withHTMLChildren={false}>
              <PostBody
                as="section"
                ref={postBodyRef}
                className="load-external-scripts"
                dangerouslySetInnerHTML={{ __html: postHtml }}
              />
            </Highlight>

            {!minimalMode && (
              <ArticleFooter as="footer" space="large">
                <MailingListCTA
                  subscriberCount={subscriberCount}
                  title={siteConfig.mailingList.post.title}
                  body={siteConfig.mailingList.post.body}
                />
                {siteConfig.hiring && <HiringCTA />}
                <TagList tags={tags} />
              </ArticleFooter>
            )}
            {!minimalMode && (
              <WebMentions
                webMentions={webMentionsFeed}
                link={url}
                shareText={`${title} — ${excerpt}`}
                authorTwitter={authors[0].twitter}
              />
            )}
          </Container>
        </PostInner>
      </Article>
    </ApplauseProvider>
  );
};

Post.propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  feature_image: PropTypes.string,
  published_at: PropTypes.string.isRequired,
  published_at_pretty: PropTypes.string.isRequired,
  updated_at_pretty: PropTypes.string,
  updated_at: PropTypes.string,
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string.isRequired,
      twitter: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  subscriberCount: PropTypes.number.isRequired,
  tags: TagsPropType,
  minimalMode: PropTypes.bool,
};
