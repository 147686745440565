import PropTypes from 'prop-types';
import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';

const { color, spacing } = styles;

export const PostImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: ${color.lightest} no-repeat center center;
  background-size: cover;
  ${(props) => props.image && { backgroundImage: `url(${props.image})` }};
  border-radius: ${spacing.borderRadius.small}px;
  border: 1px solid ${color.border};
`;

PostImage.propTypes = {
  image: PropTypes.string,
};
