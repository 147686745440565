import { useEffect, useState } from 'react';
import * as JsSearch from 'js-search';

export function useSearch(posts) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const dataToSearch = rebuildIndex(posts);
    setSearchData(dataToSearch);
  }, []);

  const handleSearch = (value) => {
    setSearchQuery(value);
    const queryResult = searchData.search(value);
    setSearchResults(queryResult);
  };

  return [searchQuery, searchResults, handleSearch];
}

function rebuildIndex(posts) {
  const dataToSearch = new JsSearch.Search('url');
  // // defines a indexing strategy for the data
  dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
  // // defines the sanitizer for the search to prevent some of the words from being excluded
  dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
  // // defines the search index
  dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('url');

  dataToSearch.addIndex('title');
  dataToSearch.addIndex('excerpt');

  dataToSearch.addDocuments(posts);

  return dataToSearch;
}
