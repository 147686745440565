import React from 'react';
import PropTypes from 'prop-types';
import { Header as MarketingHeader } from '@chromatic-com/tetra';

import { links } from './headerData';

export const Header = ({ theme = 'light' }) => (
  <MarketingHeader desktopActiveId="blog" theme={theme} links={links} />
);

Header.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};
