import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, styled } from '@storybook/theming';
import { styles, animation } from '@storybook/design-system';
import pluralize from 'pluralize';

const { color, typography } = styles;
const { inlineGlow } = animation;

const Count = styled.div`
  font-weight: ${typography.weight.bold};
  font-size: ${typography.size.s2}px;
  line-height: ${typography.size.m1}px;
  color: ${color.positive};
  text-decoration: none;
`;

const SubText = styled.div`
  color: ${color.mediumdark};
  font-size: ${typography.size.s1}px;
  line-height: ${typography.size.s1}px;
  margin-bottom: 6px;
  padding-right: 12px;
`;

const Clap = styled.button`
  font-size: ${styles.typography.size.l1}px;
  line-height: 42px;
  padding-right: 15px;
  padding-left: 4px;
  background: none;
  border: 0;
  appearance: none;

  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ShareInner = styled.div`
  display: inline-flex;
  align-items: flex-end;

  ${(props) =>
    props.isLoading &&
    css`
      ${Clap}, ${Count}, ${SubText} {
        overflow: hidden;
        span {
          ${inlineGlow};
        }
      }
    `};
`;

ShareInner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export const ApplauseSummary = ({ count, isLoading, handleClick }) => {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    let timerId;

    if (mouseDown) {
      timerId = setInterval(() => handleClick(), 150);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [mouseDown]);

  const handleMouseDown = (event) => {
    if (event.button !== 2) {
      setMouseDown(true);
    }
  };

  return (
    <ShareInner isLoading={isLoading}>
      <Clap
        aria-label="clap"
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown}
        onTouchEnd={() => setMouseDown(false)}
        onMouseUp={() => setMouseDown(false)}
      >
        <span>👏</span>
      </Clap>
      <div>
        <Count>
          <span>{count.toLocaleString()}</span>
        </Count>
        <SubText>
          <span>{pluralize('Clap', count)}</span>
        </SubText>
      </div>
    </ShareInner>
  );
};

ApplauseSummary.propTypes = {
  count: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ApplauseSummary.defaultProps = {
  count: 0,
  isLoading: false,
};
