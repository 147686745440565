import { styled } from '@storybook/theming';
import { styles } from '@storybook/design-system';
import { rgba } from 'polished';
import { GatsbyLink } from './GatsbyLink';

export const TagItem = styled(GatsbyLink)`
  background: ${styles.background.app};
  border-radius: ${styles.spacing.borderRadius.small}px;
  padding: 5px 10px;
  font-size: ${styles.typography.size.s2}px;
  line-height: ${styles.typography.size.m2}px;
  position: relative;
  color: ${styles.color.darkest};
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.08) 0 3px 10px 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    border-color: ${rgba(styles.color.secondary, 0.5)};

    &:after {
      opacity: 1;
    }
  }
`;
