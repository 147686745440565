import React from 'react';
import { styled } from '@storybook/theming';
import { styles, Link } from '@storybook/design-system';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { Stack } from '../Stack';

const { breakpoint, spacing, typography } = styles;

const HiringCTAWrapper = styled.div`
  background: ${styles.background.positive};
  border: ${(props) => props.theme.borders.positive};
  border-radius: 4px;
  padding: ${spacing.padding.large}px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;

  @media (min-width: ${breakpoint}px) {
    text-align: left;
  }

  ${Stack} {
    flex: 0 1 100%;

    @media (min-width: ${breakpoint}px) {
      flex: 1;
    }
  }
`;

const ActionWrapper = styled.div`
  flex: 0 0 100%;
  min-width: 110px;
  margin-top: 1rem;

  @media (min-width: ${breakpoint}px) {
    flex: 0 0 auto;
    margin-top: 0;
    margin-left: ${(props) => props.theme.space.large}px;
  }
`;

const ViewJobsLink = styled(Link)`
  display: inline-block;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3em;
  padding: ${(props) =>
    `${props.theme.space.small}px ${props.theme.space.medium}px`};
  font-size: ${typography.size.s2}px;
  line-height: ${typography.size.s3}px;
  font-weight: ${typography.weight.extrabold};
  text-align: center;
`;

export const HiringCTA = () => (
  <HiringCTAWrapper>
    <Stack space={4}>
      <Heading level="4" as="h3">
        We&rsquo;re hiring!
      </Heading>
      <Text variant="caption">
        Join the team behind Storybook and Chromatic. Build tools that are used
        in production by 100s of thousands of developers. Remote-first.
      </Text>
    </Stack>

    <ActionWrapper>
      <ViewJobsLink
        href="https://www.chromatic.com/company/jobs"
        rel="noopener noreferrer"
        target="_blank"
      >
        View jobs
      </ViewJobsLink>
    </ActionWrapper>
  </HiringCTAWrapper>
);
